import React, { lazy } from "react";
import "./App.css";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link as RouterLink,
	Redirect,
} from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";

import { homepage } from "../package.json";
import { useAuth0 } from "@auth0/auth0-react";

const Splash = lazy(() => import("./containers/Splash"));
const Home = lazy(() => import("./containers/Home"));

let basename = "";
if (homepage) {
	const fullUrl = new URL(homepage);
	basename = fullUrl.pathname.endsWith("/")
		? fullUrl.pathname.slice(0, fullUrl.pathname.length - 1)
		: fullUrl.pathname;
}
const Loading = () => {
	return (
		<div className="loading">
			<CircularProgress />
		</div>
	);
};
const Error = () => (
	<div className="error">
		<h1>Error</h1>
		<h3>Error Loading Page</h3>
		<Button to="/" component={RouterLink}>
			Go Home
		</Button>
	</div>
);
function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary">
			{"Copyright © "}
			{new Date().getFullYear()} ERC-IMPACT and The University of Maryland,
			College Park
		</Typography>
	);
}
const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		minHeight: "100vh",
		background: "#eec57f",
	},
	main: {
		marginTop: theme.spacing(8),
		marginBottom: theme.spacing(2),
	},
	footer: {
		padding: theme.spacing(2, 1),
		marginTop: "auto",
		backgroundColor:
			theme.palette.type === "light"
				? theme.palette.grey[200]
				: theme.palette.grey[800],
	},
}));
export default function StickyFooter() {
	const classes = useStyles();
	const { user, isLoading, isAuthenticated } = useAuth0();
	console.log(user, isLoading, isAuthenticated);
	return (
		<div className={classes.root}>
			<CssBaseline />
			<Router basename={basename}>
				<React.Suspense fallback={<Loading />}>
					<Switch>
						<Route exact path="/">
							<Splash />
						</Route>
						<Route path="/home">
							<Home />
						</Route>
						{/* <Redirect to="/" /> */}
						<Route>
							<Error />
						</Route>
					</Switch>
				</React.Suspense>

				<footer className={classes.footer}>
					<Container maxWidth="sm">
						<Copyright />
					</Container>
				</footer>
			</Router>
		</div>
	);
}
