import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
// http://mcg.mbitson.com/#!?primary=%23efa122&secondary=%2384d1e2&themename=mcgtheme
export const primary = {
	50: "#fdf4e4",
	100: "#fae3bd",
	200: "#f7d091",
	300: "#f4bd64",
	400: "#f1af43",
	500: "#efa122",
	600: "#ed991e",
	700: "#eb8f19",
	800: "#e88514",
	900: "#e4740c",
	A100: "#ffffff",
	A200: "#ffebdb",
	A400: "#ffcfa8",
	A700: "#ffc08f",
	contrastDefaultColor: "dark",
};

export const secondary = {
	50: "#f0f9fc",
	100: "#daf1f6",
	200: "#c2e8f1",
	300: "#a9dfeb",
	400: "#96d8e6",
	500: "#84d1e2",
	600: "#7cccdf",
	700: "#71c6da",
	800: "#67c0d6",
	900: "#54b5cf",
	A100: "#ffffff",
	A200: "#fdffff",
	A400: "#caf3ff",
	A700: "#b1eeff",
	contrastDefaultColor: "dark",
};

export default createMuiTheme({
	spacing: 8, // Default is 8
	typography: {
		// htmlFontSize: 14,
		// fontSize: 14,
		fontFamily: [
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Roboto",
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
	},
	palette: {
		primary,
		secondary,
		success: green,
		error: red,
	},
	shape: {
		borderRadius: 4,
	},
	props: {
		MuiTab: {
			disableRipple: true,
		},
	},
	mixins: {
		toolbar: {
			minHeight: 48,
		},
	},
});
