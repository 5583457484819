import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import { ThemeProvider } from "@material-ui/styles";

import Theme from "./styles/theme.js";

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={Theme}>
			<Auth0Provider
				domain={process.env.REACT_APP_DOMAIN}
				clientId={process.env.REACT_APP_CLIENT_ID}
				redirectUri={window.location.origin + "/home"}
			>
				<App />
			</Auth0Provider>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
